import { Environment, CameraControls, useCursor, Html } from '@react-three/drei'
import { FontLoader } from 'three/examples/jsm/loaders/FontLoader.js'
import { TextGeometry } from 'three/examples/jsm/geometries/TextGeometry.js'
import { useFrame, extend } from '@react-three/fiber'
import { useRef, useState, useEffect } from 'react'
import { socket } from '../../socket.js';
import { useLocalStorage } from '../../useLocalStorage.js';
import './scene.css';
import HelvetikerFont from "three/examples/fonts/helvetiker_regular.typeface.json";

extend({ TextGeometry })

const font = new FontLoader().parse(HelvetikerFont);

export default function Scene({ update, myID, myProfile, isDone, cubes, users, myProfileName }) {
    const [hovered, setHovered] = useState(false)
    const [ recoveredRole, setRecoveredRole ] = useLocalStorage("role")
    const ref = useRef()

    useEffect(() => {
        // Reload
        socket.emit('reload')

        // Récupération du rôle
        console.log('USEEFFECT', myID, recoveredRole, myProfile)
        if (recoveredRole != null && recoveredRole in [1, 2, 3])
            socket.emit('selectRole', { profile: recoveredRole, ID: myID });
    }, [myID])
    
    useEffect(() => {
        const init = (args) => {
            console.log('init', args)
            update(args)
        }
        const onSelectRoleIn = (args) => {
            console.log('selectRoleIn app', args)
            update(args)
        }
        const onClickOnCubeIn = (args) => {
            console.log('clickOnCubeIn app', args)
            update(args)
        }
        const onDisconnectIn = (args) => {
            console.log('onDisconnectIn app', args)
            update(args)
        }

        socket.on('init', init);
        socket.on('selectRoleIn', onSelectRoleIn);
        socket.on('clickOnCubeIn', onClickOnCubeIn);
        socket.on('disconnectIn', onDisconnectIn);
        
        return () => {
            socket.off('init', init)
            socket.off('selectRoleIn', onSelectRoleIn)
            socket.off('clickOnCubeIn', onClickOnCubeIn)
            socket.off('onDisconnectIn', onDisconnectIn)
        }
    })

    useCursor(hovered)

    useFrame((state) => {
        // console.log (state.camera.position)
    })

    const onSelectRole = (e, profile) => {
        console.log('onSelectRole', e, profile, socket, e.target, e.target.classList.contains('disabled'))
        if (e.target.classList.contains('disabled'))
            return;
        console.log('AFTER');
        setRecoveredRole(profile)
        socket.emit('selectRole', { profile, ID: myID });
    }
    const onChangeRole = () => {
        console.log('onChangeRole');
        setRecoveredRole(null)
        socket.emit('changeRole', { ID: myID });
    }
    const onReset = () => {
        setRecoveredRole(null)
        socket.emit('reset', { ID: myID });
    }
    const onClickCube = (e, key) => {
        // Si l'écran de félicitations est affiché, plus rien n'est cliquable
        if (isDone)
            return;
        console.log('onClickCube', e, key)
        
        if (myProfile == 3)
            return
        else if (key == myProfile || key == 3) {
            console.log('Click sur mon cube -- ok')
            socket.emit('clickOnCube', { ID: myID, cube: key, profile: myProfile });
        }
        else if ([1, 2].includes(key)) {
            // S'il est déjà orange, on ne prend pas le click en compte
            if (
                e.eventObject.material.color.r == 1 &&
                e.eventObject.material.color.g == 0.5
            ) {
               return;
            }

            let r = e.eventObject.material.color.r
            let g = e.eventObject.material.color.g

            e.eventObject.material.color.r = 1;
            e.eventObject.material.color.g = 0.5

            console.log('Click sur mauvais cube', e.eventObject)
            setTimeout(() => {
                e.eventObject.material.color.r = r;
                e.eventObject.material.color.g = g
            }, 1000);
        }
    } 
    const onPointerOver = (k) => {
        console.log('onPointerOver', k, myProfile, typeof cubes, cubes instanceof Array, cubes);
        if (
            (k == 1 && myProfile == 1) ||
            (k == 2 && myProfile == 2) ||
            (k == 3 && cubes.hasOwnProperty(1) && cubes[1] == true && cubes.hasOwnProperty(2) && cubes[2] == true && cubes.hasOwnProperty(3) && typeof cubes[3] == 'object' && cubes[3].hasOwnProperty(myProfile) && cubes[3][myProfile] == false)
        ) 
            setHovered(true)
    }
    const onPointerOut = () => {
        if (hovered) 
            setHovered(false)
    }

    const getThirdColor = () => {
        console.log('Third color', cubes);

        if (isDone)
            return 'green'
        
        if (typeof cubes[3] == 'object' && cubes[3].hasOwnProperty(1) && cubes[3][1] == true) 
            return 'blue'
        
        if (typeof cubes[3] == 'object' && cubes[3].hasOwnProperty(2) && cubes[3][2] == true) 
            return 'purple'

        return 'red'
    }
    

    console.log('USERS', users)
    return <>
        <Html>
        {isDone && 
                <div id="good">
                    <p>Félicitations !</p>
                    <button id="reset" onClick={onReset}>RESET</button>
                </div>
            }
        </Html>

	  {(myProfile == null) ?

        <Html className='container'>
            
            <div id="introChoix">
                    <>
                        <h2 id="titre-role">Choisissez un rôle</h2>
                        <div>
                            <button id="technicien-role" onClick={(e) => onSelectRole(e, 1)} className={`${(users['1'] != null) ? 'disabled': ''}`}>Technicien</button>
                            <button id="enqueteur-role" onClick={(e) => onSelectRole(e, 2)} className={`${(users['2'] != null) ? 'disabled': ''}`}>Enquêteur</button>
                            <button id="spectateur-role" onClick={(e) => onSelectRole(e, 3)} className={`${(users['3'] != null) ? 'disabled': ''}`}>Spectateur</button>
                        </div>
                    </>
                  {/* <>
                        <h2 id="titre-role">Vous êtes {myProfileName}</h2>
                        <button id="change-role" onClick={onChangeRole}>Change rôle</button>
                    </> */}
            </div> 
        </Html>
    :
    <>
        <hemisphereLight intensity={0.5}/>
    
        <group ref={ref} >
            <mesh 
                key={0} 
                position={[-700,-400,100]} 
                scale={100} 
                onClick={(e) => onClickCube(e, 1)} 
                onPointerOver={(e) => onPointerOver(1)} 
                onPointerOut={onPointerOut}
            > 
                {/*<boxGeometry/>*/}
                <textGeometry args={['1', {font, size:2, height: 1}]}/>
                <meshStandardMaterial color={(cubes[1] === true) ? 'green' : 'red'} />
            </mesh>
            
            <mesh 
                key={1} 
                position={[300,-400,550]} 
                scale={100} 
                onClick={(e) => onClickCube(e, 2)}
                onPointerOver={(e) => onPointerOver(2)} 
                onPointerOut={onPointerOut}
            >
                {/*<boxGeometry/>   */}
                <textGeometry args={['2', {font, size:2, height: 1}]}/>
                <meshStandardMaterial color={(cubes[2] === true) ? 'green' : 'red'} />
            </mesh>
            <mesh 
                key={2} 
                position={[100,-400,-700]} 
                scale={100} 
                onClick={(e) => onClickCube(e, 3)}
                onPointerOver={(e) => onPointerOver(3)} 
                onPointerOut={onPointerOut}
            >
                {/*<boxGeometry/>   */}
                <textGeometry args={['3', {font, size:2, height: 1}]}/>
                <meshStandardMaterial color={getThirdColor()} />
            </mesh>
        </group>

        <Environment
            background
            files={[
                './background/0/px.png',
                './background/0/nx.png',
                './background/0/py.png',
                './background/0/ny.png',
                './background/0/pz.png',
                './background/0/nz.png',
            ]}
        />

        <CameraControls makeDefault azimuthRotateSpeed={-0.5} polarRotateSpeed={-0.5} />
    </>
            }

    </>
}