import { useState, useEffect } from "react";

const getStorageValue = (key, defaultValue = null) => {
    const saved = localStorage.getItem(key);
    const initial = JSON.parse(saved);

    return initial || defaultValue;
}

const setStorageValue = (key, value = null) => {
    localStorage.setItem(JSON.stringify(value))
}

export const useLocalStorage = (key, defaultValue) => {
    const [value, setValue] = useState(() => {
        return getStorageValue(key, defaultValue);
    });

    useEffect(() => {
        localStorage.setItem(key, JSON.stringify(value));
    }, [key, value]);

    return [value, setValue];
};