import { useState, useEffect, useRef } from 'react'
import { socket } from '../../socket.js';
import './chat.css';

const Chat = ({ myID, myProfile, myProfileName }) => {
    const ref = useRef();
    const [messages, setMessages] = useState([])
    const [text, setText] = useState('');

    useEffect(() => {
        if (ref.current) {
            console.log('ref', ref, ref.current, ref.current.scrollHeight)
            ref.current.scrollTo({ top: ref.current.scrollHeight, behavior: "smooth" })
        }
    }, [messages])

    const handleSubmit = (e) => {
        console.log('e', e)
        // Prevent the browser from reloading the page
        e.preventDefault();

        // Read the form data
        const form = e.target;
        const formData = new FormData(form);
        console.log('formData', formData, formData.get('message'), text, myID, myProfile, myProfileName)
        setText('')

        socket.emit('message', { message: { ID: myID, content: text, profileName: myProfileName, profile: myProfile } });
    }

    const displayMessages = () => {
        return <>
            {messages.map((message, i) => {
                console.log('i', i, 'message', message)
                return (
                    <div className={`row ${(myProfile === message.profile) ? 'me' : ''}`} key={i}>
                        <div className="headline">
                            <div className="name">{message.profileName}</div>
                            <div className="date">A l'instant</div>
                        </div>
                        
                        <div className="message">
                            {message.content}
                        </div>
                    </div>
                )
            })}
        </>
    }

    useEffect(() => {
        const onMessageIn = (args) => {
            setMessages([ ...messages, args.message ])
        }

        socket.on('messageIn', onMessageIn);
    
        return () => {
            socket.off('messageIn', onMessageIn)
        }
    })

    return <>
            <div id="chat">
                <div id="chat-container" ref={ref}>
                    <div id="chat-slide">
                        <div className="row me">
                            <div className="headline">
                                <div className="name">Tony SAHRAOUI</div>
                                <div className="date">4 juin 16:10</div>
                            </div>
                            
                            <div className="message">
                                Bonjour Philippe, comment vas-tu ?
                            </div>
                            <div className="message">
                                Tu veux que l'on fasse un point ensemble aujourd'hui sur le dossier du client Iteachwell sur Harkhan ?<br />
                                L'idée c'est de revoir ensemble les demandes spécifiques de développement.
                            </div>
                        </div>
                        <div className="row">
                            <div className="headline">
                                <div className="name">Philippe RAYMOND</div>
                                <div className="date">4 juin 17:19</div>
                            </div>
                            
                            <div className="message">
                                Hello, oui d'accord, on peut s'appeler dans 1/2h si tu veux.
                            </div>
                            <div className="message">
                                Je t'envoie un lien meet quand je suis prêt.
                            </div>
                            <div className="message">
                                A tout à l'heure !
                            </div>
                        </div>
                        
                        <div className="row me">
                            <div className="headline">
                                <div className="name">Tony SAHRAOUI</div>
                                <div className="date">10 juil., 16:59</div>
                            </div>
                            
                            <div className="message">
                                Bonjour Philippe, comment vas-tu ?
                            </div>
                        </div>
                        <div className="row">
                            <div className="headline">
                                <div className="name">Philippe RAYMOND</div>
                                <div className="date">mar. 07:19</div>
                            </div>
                            
                            <div className="message">
                                Très bien et toi ? Nous devons faire une réunion cet après-midi avec le client Duchemol Henry, il faudrait prévoir la présentation de la plateforme.
                            </div>
                        </div>

                        <div className="row me">
                            <div className="headline">
                                <div className="name">Tony SAHRAOUI</div>
                                <div className="date">hier 14:57</div>
                            </div>
                            
                            <div className="message">
                                D'accord, je m'occupe de préparer cette présentation. Je t'envoie un message quand c'est prêt.
                            </div>
                        </div>

                        <div className="row me">
                            <div className="headline">
                                <div className="name">Tony SAHRAOUI</div>
                                <div className="date">Auj. 14:57</div>
                            </div>
                            
                            <div className="message">
                                D'accord, je m'occupe de préparer cette présentation. Je t'envoie un message quand c'est prêt.
                            </div>
                        </div>

                        <div className="row">
                            <div className="headline">
                                <div className="name">Philippe RAYMOND</div>
                                <div className="date">1min</div>
                            </div>
                            
                            <div className="message">
                                Super, merci.
                            </div>
                        </div>

                        <div className="row me">
                            <div className="headline">
                                <div className="name">Tony SAHRAOUI</div>
                                <div className="date">A l'instant</div>
                            </div>
                            
                            <div className="message">
                                Je t'en prie.
                            </div>
                            <div className="message">
                                A plus tard.<br />
                                Passe une bonne journée !<br />
                                A demain
                            </div>
                        </div>

                        {displayMessages()}
                    </div>
                </div>
                <div id="chat-form">
                    <div className="separator"></div>
                    <form method="post" onSubmit={handleSubmit}>
                        <input type="text" name="message" value={text} onChange={e => setText(e.target.value)}/>
                        <button type="submit">
                            <svg viewBox="0 0 24 24" width="24px" height="24px" className="GfYBMd NZG6ge"><path d="M6.7,17.9v-3.7l4-1.8c0.4-0.2,0.4-0.7,0-0.9l-4-1.8V6.1L19.8,12L6.7,17.9z M23.7,11.5L5.4,3.3  c-0.1,0-0.1,0-0.2,0C5,3.3,4.7,3.5,4.7,3.8v5.9v4.8v5.9c0,0.3,0.2,0.5,0.5,0.5c0.1,0,0.1,0,0.2,0l18.3-8.2  C24.1,12.3,24.1,11.7,23.7,11.5z"></path></svg>
                            {">"}
                        </button>
                    </form>
                </div>
            </div>
    </>
}

export default Chat