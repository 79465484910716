import { io } from 'socket.io-client';

const URL = process.env.NODE_ENV === 'production' ? 'https://game.test-harkhan.com:4000' : 'http://storygame.loc:4000';

console.log('PROCESS.env', process.env.NODE_ENV, URL)
const socket = io(URL, {
        transports: ['websocket']
    }
);

socket.io.on("error", (error) => {
    console.log('socket io error', error);
});
socket.io.on("ping", () => {
    console.log('socket io ping');
});
socket.io.on("reconnect", (attempt) => {
    console.log('socket io reconnect', attempt);
});
socket.io.on("reconnect_attempt", (attempt) => {
    console.log('socket io reconnect_attempt', attempt);
});
socket.io.on("reconnect_error", (error) => {
    console.log('socket io reconnect_error', error);
});
socket.io.on("reconnect_failed", () => {
    console.log('socket io reconnect_failed');
});

export { socket }