import { Canvas } from '@react-three/fiber'
import { useState, useMemo } from 'react'
import { useLocalStorage } from './useLocalStorage.js';
import Chat from './components/Chat/chat.js'
import Scene from './components/Scene/scene.js'
import { v4 as uuid } from "uuid";

export default function App() {

    const [ myID ] = useLocalStorage("myID", uuid())
    const [ myProfile, setMyProfile] = useState(null)
    const [ isDone, setIsDone ] = useState(false)
    const [ users, setUsers ] = useState({ 1: null, 2: null, 3: null })
    const [ cubes, setCubes ] = useState({ 1: 0, 2: 0, 3: { 1: 0, 2: 0 } })

    console.log('myID', myID, 'users', users, 'myProfile', myProfile)

    const myProfileName = useMemo(() => {
        if (myProfile === '1')
            return 'Technicien'
        else if (myProfile === '2') 
            return 'Enquêteur'
        else if (myProfile === '3')
            return 'Spectateur'
        else 
            return null
    }, [myProfile])
    
    const update = ({ connectedUsers: u, cubes: c }) => {
        console.log('UPDATE');
        setUsers({ users, ...u })
        setCubes({ cubes, ...c })

        let result = (
            Object.values(c[3]).filter((v, k) => v !== true).length > 0 ||
            c[1] === false ||
            c[2] === false
        ) ? false : true;

        const me = Object.entries(u).filter(([k, v], i) => { console.log('SEARCH', k, v, k === myID); return v === myID })
        console.log('ME', me)
        let recoveredProfile = (me && me instanceof Array && me.length > 0) ? 
            me[0][0] :
            null
        if (myProfile !== recoveredProfile)
            setMyProfile(recoveredProfile)

        console.log('UPDATE', 'myID', myID, 'recoveredProfile', recoveredProfile, 'users', u, 'cubes', c, 'result', result, 'cond 1', Object.values(c[3]).filter((v, k) => { console.log('KV', k, v); return v !== true }).length > 0, 'cond 2', c[1], c[1] == false, c[2], c[2] == false )

        if (!result && isDone) 
            setIsDone(false) 
        else if (result && !isDone)
            setIsDone(true)
    }

    console.log(myID)

    return <>
        {/* <div id="content"> */}
            <Canvas flat camera={{ fov: 45, position: [-0.68, 0.55, -0.63], zoom:1 }}>
                <Scene update={update} myID={myID} myProfile={myProfile} isDone={isDone} cubes={cubes} users={users} myProfileName={myProfileName} />
            </Canvas>
        {/* </div> */}
        
        {(myProfile != null) && <Chat myID={myID} myProfile={myProfile} myProfileName={myProfileName} />}
		</>
}
